// Сторонние зависимости
import md5 from 'blueimp-md5';

// Vuex
import store from '@/store/index'

// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';

export default class InterCarrierService {
  /**
   * Получение всех данных
   *
   * @returns {Promise<Array<Object>|*[]>}
   */
  static async getData() {
    const query = 'sin2:/v:1c81451d-9245-41af-90c2-2dec43d15e53';

    await store.dispatch('cache/removeItem', md5('core-read' + query));

    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        )
      );
    } catch (e) {
      console.error('InterCarrierService::getData', e);

      return [];
    }
  }
}
